import React from "react";

import type { HeadFC } from "gatsby";
import { graphql } from "gatsby";

import { Box } from "@mui/system";

import { BaseHead, Layout } from "../components";

const LegalsPage = ({
  data: {
    markdownRemark: { html },
  },
}: any) => {
  return (
    <Layout>
      <Box dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  );
};

export const query = graphql`
  query contactQuery {
    markdownRemark(frontmatter: { key: { eq: "legals" } }) {
      html
    }
  }
`;

export const Head: HeadFC = () => <BaseHead title="Mentions Légales" />;

export default LegalsPage;
